// _list.scss
// 
// Template's lists styles.
// 


// Lists
.invoice-list,
.transactions-list {
	.ant-list-item {
		padding: 11px 0;
	}
	.ant-list-item-action .ant-btn {
		font-weight: $fw-semibold;
		color: $color-gray-12;
	}
	.ant-list-item-meta-title {
		font-size: 14px;
		font-weight: $fw-semibold;
		color: $color-gray-12;
	}
	.ant-list-item-meta-description {
		font-size: 12px;
		font-weight: $fw-semibold;
		color: $color-gray-7;
	}
	.amount {
		font-size: 14px;
		font-weight: $fw-semibold;
		color: $color-gray-7;
	}
}

.transactions-list {
	h6 {
		font-size: 12px;
		font-weight: $fw-semibold;
		color: $color-muted;
		margin: 6px 0;
	}
	.ant-list-item {
		padding: 12px 0;

		&:first-child {
			padding-top: 0;
		}
	}
	.ant-list-item-action .ant-btn {
		font-weight: $fw-semibold;
		color: $color-gray-12;
	}
	.ant-list-item-meta-title {
		margin-bottom: 0;
	}
	.amount {
		font-size: 16px;
		font-weight: $fw-bold;
		
		span.text-warning {
			font-size: 14px;
			font-weight: $fw-semibold;
		}
	}
	.ant-list-item-meta {
		align-items: center;
	}
}

.conversations-list {
	.ant-list-item {
		padding: 11px 0;
	}
	.ant-avatar {
		border-radius: 10.5px;
		box-shadow: $shadow-2;
	}
	.ant-list-item-meta-title {
		font-size: 14px;
		font-weight: $fw-semibold;
		color: $color-gray-12;
		margin: 0;
	}
	.ant-list-item-meta {
		align-items: center;
	}
	.ant-list-item-meta-description {
		font-size: 12px;
		font-weight: $fw-semibold;
		color: $color-gray-7;
	}
	.ant-list-item-action {
		margin-left: 0;
	}
	.ant-btn-link {
		font-size: 12px;
	}
}

.layout-dashboard-rtl {
	.ant-list-item-meta-avatar {
		margin-left: 16px;
		margin-right: 0;
	}
}
// / Lists