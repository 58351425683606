// _profile.scss
// 
// Template's profile page styles.
// 


// Profile Page
.layout-profile {
	.header-control {
		.btn-sign-in,
		.ant-btn,
		.ant-dropdown-link {
			svg path {
				transition: none;
			}
		}
	}
	.ant-breadcrumb a{
		transition: none;
	}
	.ant-layout>div>div:not(.ant-affix),
	.ant-layout>div>.ant-layout-header {
		.ant-breadcrumb > span {
			.ant-breadcrumb-link a,
			.ant-breadcrumb-separator {
				color: $color-gray-1;
				transition: none;
			}
			.ant-breadcrumb-link a {
				opacity: .75;
			}
			&:last-child .ant-breadcrumb-link {
				color: $color-gray-1;
			}
		}
		.ant-page-header-heading .ant-page-header-heading-title {
			color: $color-gray-1;
		}
		.header-control .btn-sign-in span {
			color: $color-gray-1;
		}
		.header-control {
			.btn-sign-in,
			.ant-btn,
			.ant-dropdown-link {
				svg path {
					fill: $color-gray-1;
				}
			}
		}
	}
	.ant-layout-header {
		margin: 10px 20px;
	}
	.ant-card-head {
		padding: 0 16px;
	}
}
.profile-nav-bg {
	height: 300px;
	margin-top: -137.8px;
	border-radius: 12px;
	box-shadow: $shadow-1;
	background-size: cover;
	background-position: center;

	@media( min-width: $md ){
		margin-top: -87.8px;
	}
}

.settings-list {
	list-style: none;
	padding: 0;

	>li {
		padding: 12px 0;
		&:first-child {
			padding-top: 0;
		}
		span {
			margin-left: 16px;
			color: $color-gray-12;
		}
		h6 {
			font-size: 12px;
			color: $color-muted;
			margin: 0;
		}
	}
}

.projects-uploader {
    height: 100%;
	border-radius: 8px;
	border-color: $color-gray-5;
	.ant-upload {
		width: 100%;
		height: 100%;
		margin: 0;
		border-radius: 8px;
	}
	.ant-upload.ant-upload-select-picture-card {
		background-color: transparent;
	}
}
// / Profile Page