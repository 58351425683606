// _tag.scss
// 
// Template's tag styles.
// 


// Tags
.tag-status {
	padding: 2px 12px;
	font-weight: $fw-bold;
}
// / Tags