// _override.scss
//
// Overriding Ant Design's default variables, such as colors, shadows and... .
//

html {
  --antd-wave-shadow-color: $color-primary;
}
[ant-click-animating-without-extra-node="true"]::after,
.ant-click-animating-node {
  box-shadow: 0 0 0 0 $color-primary;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
}
a {
  color: $color-primary;
}
::-moz-selection {
  background: $color-primary;
}
::selection {
  background: $color-primary;
}
.ant-click-animating-node {
  box-shadow: 0 0 0 0 $color-primary;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 $color-primary;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 $color-primary;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
.ant-alert-success .ant-alert-icon {
  color: $color-success;
}
.ant-alert-warning {
  border: 1px solid $color-warning;
}
.ant-alert-warning .ant-alert-icon {
  color: $color-warning;
}
.ant-anchor-ink-ball {
  border: 2px solid $color-primary;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: $color-primary;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: $input-height-base;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: $input-height-base;
}
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: $select-hover-border-color;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: $select-hover-border-color;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: $input-height-lg;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: $input-height-lg;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: $input-height-sm;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: $input-height-sm;
}
.ant-badge-status-primary {
  background-color: $color-primary;
}
.ant-badge-status-secondary {
  background-color: $color-secondary;
}
.ant-badge-status-success {
  background-color: $color-success;
}
.ant-badge-status-warning {
  background-color: $color-warning;
}
.ant-btn {
  box-shadow: $btn-shadow;
  border-radius: $btn-border-radius-base;
}
.ant-btn,
.ant-btn-group .ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
  font-weight: $fw-semibold;
  font-size: $btn-font-size-base;
  height: $btn-height-sm;
  padding: $btn-padding-base;
}
.ant-btn-icon-only {
  font-size: $btn-font-size-base;
}
.ant-btn-lg {
  border-radius: $btn-border-radius-base;
}
.ant-btn-lg,
.ant-btn-group-lg .ant-btn,
.ant-radio-group-large .ant-radio-button-wrapper {
  height: $btn-height-lg;
  padding: $btn-padding-base;
  font-size: $btn-font-size-lg;
}
.ant-btn-icon-only.ant-btn-lg {
  font-size: $btn-font-size-lg;
}
.ant-btn-sm {
  border-radius: 4px;
}
.ant-btn-sm,
.ant-btn-group-sm .ant-btn,
.ant-radio-group-small .ant-radio-button-wrapper {
  height: $btn-height-sm;
  font-size: $btn-font-size-sm;
  padding: $btn-padding-base;
}
.ant-btn-icon-only.ant-btn-sm {
  font-size: $btn-font-size-sm;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: $btn-font-size-sm;
}
.ant-btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
}
.ant-btn-primary svg path {
  transition: all 0.3s ease;
  fill: $color-white;
}
.ant-btn-link {
  color: $color-primary;
  box-shadow: none;
}
.ant-btn-icon-only {
  width: $btn-height-base;
  height: $btn-height-base;
  border-radius: $btn-border-radius-base;
}
.ant-btn-icon-only.ant-btn-lg {
  width: $btn-height-lg;
  height: $btn-height-lg;
  border-radius: $btn-border-radius-base;
}
.ant-btn-icon-only.ant-btn-sm:not(.ant-btn-round) {
  width: $btn-height-sm;
  height: $btn-height-sm;
  border-radius: $btn-border-radius-base;
}
.ant-btn-icon-only.ant-btn-lg.ant-btn-round,
.ant-btn-icon-only.ant-btn-sm.ant-btn-round {
  width: auto;
}
.ant-btn-round {
  height: $btn-height-base;
  padding: 0 20px;
  border-radius: $btn-height-base;
}
.ant-btn-round.ant-btn-lg {
  height: $btn-height-lg;
  padding: 0 24px;
  border-radius: $btn-height-lg;
  font-size: $btn-font-size-lg;
}
.ant-btn-round.ant-btn-sm {
  height: $btn-height-sm;
  padding: 0 16px;
  border-radius: $btn-height-sm;
  font-size: $btn-font-size-sm;
}
.ant-btn-circle-outline {
  min-width: $btn-height-base;
}
.ant-btn-circle-outline.ant-btn-lg {
  min-width: $btn-height-lg;
}
.ant-btn-circle-outline.ant-btn-sm {
  min-width: $btn-height-sm;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: $btn-height-base;
  padding-right: 0;
  padding-left: 0;
  border-radius: 50% !important;
}
.ant-btn-group-lg > span > .ant-btn {
  height: $btn-height-lg;
  padding: $btn-padding-base;
  line-height: 46px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: $btn-height-lg;
  height: $btn-height-lg;
}
.ant-btn-group-sm > span > .ant-btn {
  height: $btn-height-sm;
  line-height: 30px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: $btn-height-sm;
  height: $btn-height-sm;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: $btn-border-radius-base;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: $btn-border-radius-base;
}
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: $btn-border-radius-base;
  border-bottom-left-radius: $btn-border-radius-base;
}
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: $btn-border-radius-base;
  border-bottom-right-radius: $btn-border-radius-base;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-btn-background-ghost.ant-btn-link {
  color: $color-primary;
}
a.ant-btn {
  line-height: 38px;
}
a.ant-btn-lg {
  line-height: 46px;
}
.ant-fullcalendar-value:active {
  background: $color-primary;
}
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px $color-primary inset;
}
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  background: $color-primary;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: $btn-height-base;
  line-height: 38px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: $color-primary;
}
.ant-fullcalendar-fullscreen
  .ant-fullcalendar-selected-day
  .ant-fullcalendar-value {
  color: $color-primary;
}
.ant-card-actions > li > span:hover {
  color: $color-primary;
}
.ant-card-actions > li > span > .anticon:hover {
  color: $color-primary;
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: $input-hover-border-color;
}
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $input-hover-border-color;
}
.ant-checkbox-checked::after {
  border: 1px solid $color-primary;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-primary;
  border-color: $color-primary;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $color-primary;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $input-hover-border-color;
}
.ant-calendar-input {
  color: $input-color;
}
.ant-calendar-today .ant-calendar-date {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-calendar .ant-calendar-ok-btn {
  font-weight: $fw-semibold;
  box-shadow: $btn-shadow;
  height: $btn-height-base;
  padding: $btn-padding-base;
  border-radius: $btn-border-radius-base;
  background-color: $color-primary;
  border-color: $color-primary;
  line-height: 30px;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: $btn-height-lg;
  padding: $btn-padding-base;
  border-radius: $btn-border-radius-base;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: $btn-height-sm;
}
.ant-calendar-range
  .ant-calendar-today
  :not(.ant-calendar-disabled-cell)
  :not(.ant-calendar-last-month-cell)
  :not(.ant-calendar-next-month-btn-day)
  .ant-calendar-date {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: $color-primary;
}
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: $color-primary;
}
.ant-calendar-range .ant-calendar-time-picker-input {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
  line-height: $input-height-sm;
}
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: $input-hover-border-color;
}
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: $input-border-color;
}
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  min-height: $input-height-base;
}
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: $input-height-lg;
}
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: $input-height-sm;
}
.ant-calendar-time-picker-select li:focus {
  color: $color-primary;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: $color-primary;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  background: $color-primary;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  background: $color-primary;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  background: $color-primary;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  background: $color-primary;
}
.ant-calendar-decade-panel-selected-cell
  .ant-calendar-decade-panel-decade:hover {
  background: $color-primary;
}
.ant-dropdown-menu-submenu-title-selected > a {
  color: $color-primary;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: $color-primary;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  background: $color-primary;
}
.ant-form output {
  color: $input-color;
}
.ant-form-item-control {
  line-height: normal;
}
// .ant-form-item-label {
//   line-height: 47.9999px;
// }
form
  .has-feedback
  :not(.ant-input-group-addon)
  > .ant-select
  .ant-select-selection__clear {
  right: 32px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 21px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 32px;
}
form
  .has-feedback
  .ant-input-search:not(.ant-input-search-enter-button)
  .ant-input-suffix {
  right: 32px;
}
form .has-feedback .ant-time-picker-clear {
  right: 32px;
}
.is-validating.has-feedback .ant-form-item-children-icon {
  width: $input-height-base;
  color: $color-primary;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: $color-success;
}
.has-warning .ant-form-split {
  color: $color-warning;
}
.has-warning .ant-input:hover {
  border-color: $color-warning;
}
.has-warning .ant-input:focus {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: $color-warning;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  border-color: $color-warning;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning
  .ant-input-affix-wrapper:hover
  .ant-input:not(.ant-input-disabled) {
  border-color: $color-warning;
}
.has-warning .ant-input-prefix {
  color: $color-warning;
}
.has-warning .ant-input-group-addon {
  color: $color-warning;
  border-color: $color-warning;
}
.has-warning .has-feedback {
  color: $color-warning;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: $color-warning;
}
.has-warning .ant-select-selection {
  border-color: $color-warning;
}
.has-warning .ant-select-selection:hover {
  border-color: $color-warning;
}
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning .ant-cascader-picker-arrow {
  color: $color-warning;
}
.has-warning .ant-time-picker-input {
  border-color: $color-warning;
}
.has-warning .ant-time-picker-input:focus {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: $color-warning;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffec3d;
  box-shadow: 0 0 0 2px rgba(250, 219, 20, 0.2);
}
.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: $color-warning;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: $input-border-color;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: $input-hover-border-color;
}
.ant-input-affix-wrapper{
  border: 1px solid $input-border-color;
  outline: none;
}
.ant-input {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-input:hover {
  border-color: $input-hover-border-color;
}
.ant-input-disabled:hover {
  border-color: $input-border-color;
}
.ant-input[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-input {
  min-height: $input-height-base;
}
.ant-input-lg {
  height: $input-height-lg;
}
.ant-input-sm {
  height: $input-height-sm;
}
.ant-input-group-addon {
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: $color-primary;
}
.ant-input-group-lg > .ant-input-group-addon {
  height: $input-height-lg;
}
.ant-input-group-sm > .ant-input-group-addon {
  height: $input-height-sm;
}
.ant-input-group-lg .ant-select-selection--single {
  height: $input-height-lg;
}
.ant-input-group-sm .ant-select-selection--single {
  height: $input-height-sm;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $input-hover-border-color;
}
.ant-input-affix-wrapper .ant-input-suffix {
  color: $input-color;
}
.ant-input-number {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-input-number:hover {
  border-color: $input-hover-border-color;
}
.ant-input-number-disabled:hover {
  border-color: $input-border-color;
}
.ant-input-number[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-input-number {
  min-height: $input-height-base;
}
.ant-input-number-lg {
  height: $input-height-lg;
}
.ant-input-number-sm {
  height: $input-height-sm;
}
.ant-input-number-input {
  height: 38px;
}
.ant-input-number-lg input {
  height: 46px;
}
.ant-input-number-sm input {
  height: 30px;
}
.ant-list-item-meta-title > a:hover {
  color: $color-primary;
}
.ant-mentions {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-mentions:hover {
  border-color: $input-hover-border-color;
}
.ant-mentions-disabled:hover {
  border-color: $input-border-color;
}
.ant-mentions[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-mentions {
  min-height: $input-height-base;
}
.ant-mentions-lg {
  height: $input-height-lg;
}
.ant-mentions-sm {
  height: $input-height-sm;
}
.ant-mentions-disabled > textarea:hover {
  border-color: $input-border-color;
}
.ant-mentions-measure {
  min-height: 38px;
}
.ant-mentions > textarea {
  min-height: 100%;
  font-size: 14px;
  line-height: 2;
}
.ant-menu-submenu-selected {
  color: $color-primary;
}
.ant-menu-item > a:hover {
  color: $color-primary;
}
.ant-menu-item > .ant-badge > a:hover {
  color: $color-primary;
}
.ant-menu-submenu-title:hover {
  color: $color-primary;
}
.ant-menu-item-selected {
  color: $color-primary;
}
.ant-menu-item-selected > a:hover {
  color: $color-primary;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, $color-primary, $color-primary);
}
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $color-primary;
}
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: $color-primary;
}
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: $color-primary;
  border-bottom: 2px solid $color-primary;
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: $color-primary;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: $color-primary;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $color-primary;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: $color-primary;
}
.ant-message-success .anticon {
  color: $color-success;
}
.ant-message-warning .anticon {
  color: $color-warning;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: $color-warning;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: $color-success;
}
.anticon.ant-notification-notice-icon-success {
  color: $color-success;
}
.anticon.ant-notification-notice-icon-warning {
  color: $color-warning;
}
.ant-page-header-back-button {
  color: $color-primary;
}
.ant-pagination-item:hover {
  border-color: $color-primary;
}
.ant-pagination-item:hover a {
  color: $color-primary;
}
.ant-pagination-item-active {
  border-color: $color-primary;
}
.ant-pagination-item-active a {
  color: $color-primary;
}
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: $color-primary;
}
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-pagination-options-quick-jumper {
  height: $input-height-base;
  line-height: $input-height-base;
}
.ant-pagination-options-quick-jumper input {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: $input-hover-border-color;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: $input-border-color;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-pagination-options-quick-jumper input {
  min-height: $input-height-base;
}
.ant-pagination-options-quick-jumper input-lg {
  height: $input-height-lg;
}
.ant-pagination-options-quick-jumper input-sm {
  height: $input-height-sm;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: $input-hover-border-color;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: $input-height-sm;
}
.ant-popover-message > .anticon {
  color: $color-warning;
}
.ant-progress-success-bg {
  background-color: $color-success;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: $color-success;
}
.ant-progress-status-success .ant-progress-text {
  color: $color-success;
}
.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: $color-success;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: $color-success;
}
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $color-primary;
}
.ant-radio-checked::after {
  border: 1px solid $color-primary;
}
.ant-radio-inner::after {
  background-color: $color-primary;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $color-primary;
}
.ant-radio-button-wrapper {
  height: $btn-height-base;
  line-height: 38px;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: $btn-height-lg;
  line-height: 46px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: $btn-height-sm;
  line-height: 34px;
}
.ant-radio-button-wrapper:hover {
  color: $color-primary;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $color-primary;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $color-primary;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $color-primary;
  border-color: $color-primary;
}
.ant-result-success .ant-result-icon > .anticon {
  color: $color-success;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: $color-warning;
}
.ant-select-selection {
  border: 1px solid $select-border-color;
}
.ant-select-selection:hover {
  border-color: $select-hover-border-color;
}
.ant-select-disabled .ant-select-selection:active {
  border-color: $select-border-color;
}
.ant-select-selection--single {
  height: $input-height-base;
}
.ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--single {
  height: $input-height-lg;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 46px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: $input-height-lg;
}
.ant-select-lg
  .ant-select-selection--multiple
  .ant-select-selection__rendered
  li {
  height: $input-height-base;
  line-height: $input-height-base;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 24px;
}
.ant-select-sm .ant-select-selection--single {
  height: $input-height-sm;
}
.ant-select-sm .ant-select-selection__rendered {
  line-height: 30px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: $input-height-sm;
}
.ant-select-sm
  .ant-select-selection--multiple
  .ant-select-selection__rendered
  li {
  height: 24px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-selection--multiple {
  min-height: $input-height-base;
}
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: $input-height-sm;
  line-height: 30px;
}
.ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  color: $color-primary;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: $color-primary;
}
.ant-spin {
  color: $color-primary;
}
.ant-spin-dot-item {
  background-color: $color-primary;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: $color-primary;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: $color-primary;
  background: $color-primary !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $color-primary;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: $color-primary;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $color-primary;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $color-primary;
}
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: $color-primary;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $color-primary;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $color-primary;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: $color-primary;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border-color: $color-primary;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: $color-primary;
}
.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: $color-primary;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: $color-primary;
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: $color-primary;
}
.ant-switch-checked {
  background-color: $color-primary;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: $color-primary;
}
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: $color-primary;
}
.ant-table-filter-dropdown
  .ant-dropdown-menu
  .ant-dropdown-submenu-contain-selected
  .ant-dropdown-menu-submenu-title::after {
  color: $color-primary;
}
.ant-table-filter-dropdown-link {
  color: $color-primary;
}
.ant-table-row-expand-icon {
  color: $color-primary;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: $color-primary;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: $color-primary;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  color: $color-primary;
}
.ant-tabs-ink-bar {
  background-color: $color-primary;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: $color-primary;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: $color-primary;
}
.ant-tag-checkable-checked {
  background-color: $color-primary;
}
.ant-time-picker-panel-select li:focus {
  color: $color-primary;
}
.ant-time-picker-input {
  height: $input-height-base;
  color: $input-color;
  border: 1px solid $input-border-color;
}
.ant-time-picker-input:hover {
  border-color: $input-hover-border-color;
}
.ant-time-picker-input-disabled:hover {
  border-color: $input-border-color;
}
.ant-time-picker-input[disabled]:hover {
  border-color: $input-border-color;
}
textarea.ant-time-picker-input {
  min-height: $input-height-base;
}
.ant-time-picker-input-lg {
  height: $input-height-lg;
}
.ant-time-picker-input-sm {
  height: $input-height-sm;
}
.ant-time-picker-large .ant-time-picker-input {
  height: $input-height-lg;
}
.ant-time-picker-small .ant-time-picker-input {
  height: $input-height-sm;
}
.ant-timeline-item-head-blue {
  color: $color-primary;
  border-color: $color-primary;
}
.ant-timeline-item-head-green {
  color: $color-success;
  border-color: $color-success;
}
.ant-transfer-list-search-action {
  line-height: $input-height-base;
}
.ant-transfer-list-body-with-search {
  padding-top: 64px;
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 20px;
}
.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-checkbox
  .ant-tree-checkbox-inner {
  border-color: $color-primary;
}
.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-checkbox.ant-tree-checkbox-checked
  .ant-tree-checkbox-inner::after {
  border-color: $color-primary;
}
.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-node-content-wrapper::before {
  background: $color-primary;
}
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: $color-primary;
}
.ant-tree-checkbox-checked::after {
  border: 1px solid $color-primary;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: $color-primary;
  border-color: $color-primary;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: $color-primary;
}
.ant-tree li.drag-over > span[draggable] {
  background-color: $color-primary;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: $color-primary;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: $color-primary;
}
.ant-tree
  li.ant-tree-treenode-loading
  span.ant-tree-switcher.ant-tree-switcher_close
  .ant-tree-switcher-loading-icon {
  color: $color-primary;
}
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: $color-primary;
}
.ant-select-tree-checkbox-checked::after {
  border: 1px solid $color-primary;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $color-primary;
  border-color: $color-primary;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: $color-primary;
}
.ant-select-tree
  li
  span.ant-select-icon_loading
  .ant-select-switcher-loading-icon {
  color: $color-primary;
}
.ant-select-tree
  li
  span.ant-select-tree-switcher.ant-select-tree-switcher_close
  .ant-select-switcher-loading-icon {
  color: $color-primary;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: $color-primary;
}
.ant-upload-list .ant-upload-success-icon {
  color: $color-success;
}

.ant-btn-primary {
  background-color: $color-primary;
  &:hover {
    border-color: $--ant-primary-color-hover;
    background: $--ant-primary-color-hover;
    svg path {
      fill: black;
    }
  }
}
.ant-btn-secondary {
  background-color: $color-white;
  border-color: #d9d9d9;
  color: $color-gray-12;
  &:hover {
    border-color: white;
    color: $color-primary;
  }
  &:active,
  &.active,
  &:focus {
    border-color: #096dd9;
    color: #096dd9;
  }
}
.ant-btn-dark {
  background-color: $color-dark;
  color: $color-white;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $color-dark;
    color: $color-white;
  }
  &.ant-btn-background-ghost:not(:disabled) {
    color: $color-dark;
    border-color: $color-dark;
  }
}
.ant-input {
  color: $color-gray-7;
  &:focus {
    border-color: $color-primary;
    color: $color-gray-12;
    box-shadow: none;
  }
}
.ant-select {
  font-weight: $fw-semibold;
  color: $color-gray-7;
  &.ant-select-focused {
    border-color: $color-primary;
    color: $color-gray-12;
    box-shadow: none;
  }
}
.ant-select-dropdown-menu-item {
  font-weight: $fw-semibold;
}

.ant-descriptions {
  .ant-descriptions-title {
    font-size: 14px;
    font-weight: $fw-semibold;
    color: $color-gray-12;
    margin-bottom: 14px;
  }
  .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: $fw-semibold;
    color: $color-muted;
  }
  .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: $fw-semibold;
    color: $color-gray-12;
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 6px;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
