// _variables.scss
//
// Template's variables for colors, font weight and... .
//

// Base Colors
$color-primary: #1890ff;
$color-primary-1: #9629bc;
$color-secondary: #ff3077;
$color-success: #52c41a;
$color-warning: #fadb14;
$color-danger: #f5222d;
$color-dark: #141414;
$color-white: #ffffff;
// / Base Colors

// Neutral Colors
$color-gray-1: #ffffff;
$color-gray-2: #fafafa;
$color-gray-3: #f5f5f5;
$color-gray-4: #f0f0f0;
$color-gray-5: #d9d9d9;
$color-gray-6: #bfbfbf;
$color-gray-7: #8c8c8c;
$color-gray-8: #595959;
$color-gray-9: #434343;
$color-gray-10: #262626;
$color-gray-11: #1f1f1f;
$color-gray-12: #141414;
$color-gray-13: #680789;

$color-muted: $color-gray-7;
// / Neutral Colors

// Font Weights
$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;
// / Font Weights

// Font Family
$f-family: "Roboto", Helvetica, Arial, sans-serif;
// / Font Family

// Responsive Breakpoints
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
// / Responsive Breakpoints

// Ant Design Vars
$btn-height-base: 35px;
$btn-height-lg: 48px;
$btn-height-sm: 30px;
$btn-padding-base: 0px 10px;
$btn-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
$btn-border-radius-base: 6px;
$btn-font-size-base: 14px;
$btn-font-size-lg: 16px;
$btn-font-size-sm: 14px;
$btn-font-weight: $fw-bold;
$input-color: $color-gray-7;
$input-border-color: $color-gray-5;
$input-hover-border-color: $color-primary;
$input-height-base: $btn-height-sm;
$input-height-lg: $btn-height-lg;
$input-height-sm: $btn-height-sm;
$select-color: $color-gray-7;
$select-border-color: $color-gray-5;
$select-hover-border-color: $color-primary;
// / Ant Design Vars

// Template Vars
$shadow-0: none;
$shadow-1: 0px 20px 27px rgba(0, 0, 0, 0.05);
$shadow-2: 0px 4px 6px rgba(0, 0, 0, 0.12);
$shadow-3: 0px 5px 10px rgba(0, 0, 0, 0.12);

$submenu-links-p: 10px 16px;
// / Template Vars

// color ant design
$--ant-primary-color: #1890ff;
$--ant-primary-color-hover: #40a9ff;
$--ant-primary-color-active: #096dd9;
$--ant-primary-color-outline: rgba(24, 144, 255, 0.2);
$--ant-primary-1: #e6f7ff;
$--ant-primary-2: #bae7ff;
$--ant-primary-3: #91d5ff;
$--ant-primary-4: #69c0ff;
$--ant-primary-5: #40a9ff;
$--ant-primary-6: #1890ff;
$--ant-primary-7: #096dd9;
$--ant-primary-color-deprecated-l-35: #cbe6ff;
$--ant-primary-color-deprecated-l-20: #7ec1ff;
$--ant-primary-color-deprecated-t-20: #46a6ff;
$--ant-primary-color-deprecated-t-50: #8cc8ff;
$--ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
$--ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
$--ant-primary-color-active-deprecated-d-02: #dcf4ff;
$--ant-success-color: #52c41a;
$--ant-success-color-hover: #73d13d;
$--ant-success-color-active: #389e0d;
$--ant-success-color-outline: rgba(82, 196, 26, 0.2);
$--ant-success-color-deprecated-bg: #f6ffed;
$--ant-success-color-deprecated-border: #b7eb8f;
$--ant-error-color: #ff4d4f;
$--ant-error-color-hover: #ff7875;
$--ant-error-color-active: #d9363e;
$--ant-error-color-outline: rgba(255, 77, 79, 0.2);
$--ant-error-color-deprecated-bg: #fff2f0;
$--ant-error-color-deprecated-border: #ffccc7;
$--ant-warning-color: #faad14;
$--ant-warning-color-hover: #ffc53d;
$--ant-warning-color-active: #d48806;
$--ant-warning-color-outline: rgba(250, 173, 20, 0.2);
$--ant-warning-color-deprecated-bg: #fffbe6;
$--ant-warning-color-deprecated-border: #ffe58f;
$--ant-info-color: #1890ff;
$--ant-info-color-deprecated-bg: #e6f7ff;
$--ant-info-color-deprecated-border: #91d5ff;
