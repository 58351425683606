// _space.scss
// 
// Template's space styles.
// 


.layout-dashboard-rtl {
	.ant-space-horizontal {
		direction: ltr;
	}
}