@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
// font
@font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

body {
  font-weight: 400;
  font-size: 14px;
}
.ant-layout {
  background-color: #fafafa;
}

.ant-layout-content,
.ant-layout-sider {
  position: relative;
  z-index: 1;
}

// .ant-layout-content:after {
//   content: "";
//   background-image: url("../assets/img/iconic-black.png");
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-position: bottom -350px right -300px;
//   background-size: 60%;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   opacity: 0.05;
//   top: 0;
//   z-index: -1;
// }

.ant-layout-sider {
  &::before {
    content: "";
    background-image: url("../assets/img/iconic-black2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom 33em left 9em;
    background-size: 100px;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    z-index: -1;
    top: 0;
  }
  &::after {
    content: "";
    background-image: url("../assets/img/iconic-black2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom 20px left -5px;
    background-size: 240px;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    z-index: -1;
    top: 0;
  }
}

// base variables
@border-radius-base: 6px;
@page-header-heading-title: 18px;
@font-size-base: 12px;
// colors
// @dark-1: rgba(20,20,20,0.25);
// @dark-2: #1a222b;
@primary-color-2: #1890ff;
@primary-color-3: linear-gradient(180deg, #9629bc, #ff3077);
@primary-color-4: #6d0192;
@primary-color: #212529;
@primary-1: rgb(98 102 105 / 18%);
@dark-1: rgba(255, 255, 255, 0.35);
@dark-2: rgba(255, 255, 255, 0.55);
//heading
@heading-color: rgba(@primary-color, 85%);

// sider
@layout-sider-background: @primary-color-3;
@menu-bg: transparent;
@menu-item-color: @primary-color;
@menu-highlight-color: @primary-color;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
.ant-layout-sider-trigger {
  background-color: @primary-color;
}

//form
@label-color: @text-color;
@border-color-split: #dddddd;

//scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: @primary-color;
  border-radius: 10px;
}

.ant-layout-header {
  background-color: transparent;
}

//btn
.ant-btn {
  // background: @primary-color;
  // color: #fff;
  border-radius: 3px;
}
.ant-btn-icon-only {
  background: transparent;
  color: #000;
}
.ant-btn-dangerous {
  color: #fff !important;
  background: @error-color !important;
}
.ant-card-type-inner .ant-card-head {
  background-color: transparent !important;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
  // box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-color: transparent;
  padding: 0 !important;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #2a132e;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

.ant-drawer-body .ant-page-header {
  height: 100%;
}

.ant-drawer-body .ant-card-actions {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.ant-table {
  --tw-shadow-color: #e5e7eb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
  // box-shadow: 0 10px 30px -5px var(--tw-shadow-color);
}

.ant-drawer-header-close-only .ant-drawer-header-title {
  margin-left: auto;
  flex: none;
}
.ant-drawer-body .ant-page-header-heading {
  box-shadow: none !important;
  padding: 0px 20px;
}
.ant-page-header-content {
  padding-top: 0;
}

.ant-drawer-body {
  .ant-card-body {
    padding: 16px !important;
  }
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(255, 255, 255, 0.8);
}
.ant-table-thead th.ant-table-column-sort {
  background: #fff;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  outline: 1px solid #f0f0f0;
  border: none;
}

.ant-card.custom > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  color: white;
  border-radius: 6px 6px 0 0;
  margin-inline: -24px;
  padding-inline: 20px;
  padding-block: 10px;
  background-color: @primary-color-2;
}

.ant-card.custom-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background-color: @primary-color-2;
  color: white;
  border-radius: 6px 0px 0 0;
  font-size: 14px;
}

.bg-gradient-primary {
  background-image: @primary-color-2;
}
.drawer .ant-page-header-content {
  max-width: 100%;
}
.ant-page-header-content {
  // max-width: 80%;
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
}

.appsflyerChart_box {
  width: 49%;
}

@media only screen and (max-width: 1440px) {
  .ant-page-header-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .appsflyerChart {
    flex-direction: column;
  }
  .stackedBarChartContent {
    margin-top: 16px;
  }

  .appsflyerChart_box {
    width: 100%;

    & + & {
      margin-top: 24px;
    }
  }
}

.ant-collapse-header-text {
  width: 100%;
}

// .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
//   border-right-width: 0px;
// }

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;

  svg {
    color: #1890ff;
  }
}

.ant-card-actions {
  li {
    z-index: 2;
  }
}

.ant-layout-content {
  background-color: #fff;
  margin: 24px;
}

.text-color-primary {
  color: #1890ff;
}

.text-color-golden-purple {
  color: #531dab;
}

.text-color-info-studio {
  color: #faaa06;
}

.general_studio {
  & + & {
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: #faaa06;
      display: inline-block;
      border-radius: 50%;
      margin: 0 8px;
    }
  }
}

.ant-sidebar {
  background: linear-gradient(180deg, #b529b8 0%, #9b216e 20%, #4b155e 70%);

  .ant-menu {
    margin-top: 50px !important;
  }

  .ant-menu-item {
    margin: 0;
    width: 100% !important;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px;
    font-weight: 400;
    position: relative;
  }

  .ant-menu-item-selected,
  .menu-item-selected {
    background-color: #e6f7ff !important;
    color: #1890ff !important;
    font-weight: normal !important;

    &::after {
      content: "";
      width: 2px;
      height: 40px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #1890ff;
    }
  }

  .menu-item-selected {
    &::after {
      width: 2px;
      height: 40px;
      background-color: #1890ff;
      transform: scaleY(1) !important;
      opacity: 1 !important;
    }
  }
}

.logoSidebar {
  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.transform-initial {
  text-transform: initial;
}

.chart_nodata {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 1;
  transform: translateY(-50%);
}

.no-footer .ant-modal-confirm-btns {
  display: none !important;
}
.ant-menu-submenu {
  background-color: white;
  margin-top: -5px !important;
  padding: 0 !important;
}

.ant-menu-submenu-title {
  padding-left: 15px !important;
  margin: 0 !important;
}
.ant-menu.ant-menu-sub.ant-menu-inline {
  margin: 0 !important;
}
.ant-menu-title-content {
  font-size: 12px !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item-icon {
  margin-left: 15px;
}

.chartDropdown {
  ul li:first-child {
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.highcharts-title {
  opacity: 0;
  text-indent: -9999px;
}
//ant-select-item ant-select-item-group
.custom-select {
  .ant-select-item {
    padding: 5px 10px !important;
    font-size: 12px;
  }
  .rc-virtual-list {
    position: relative;
    border-width: 1px;
    border-color: #b37feb;
    border-radius: 5px;
  }
  .rc-virtual-list-scrollbar-thumb {
    background-color: rgba(239, 219, 255, 1) !important;
  }

  .rc-virtual-list-scrollbar {
    background-color: rgba(250, 243, 255, 1) !important;
  }

  .ant-select-item-option-active {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: rgba(98, 102, 105, 0.18);
  }
}
.ant-select-dropdown,
.ant-select-dropdown-placement-bottomLeft {
  padding-top: 0;
  padding-bottom: 0;
}

.rc-virtual-list-holder,
.ant-select-dropdown-placement-bottomLeft,
.rc-virtual-list-scrollbar-show {
  border-radius: 4px;
}

.rangePickerPMP,
.rangePickerPMP .ant-picker:hover,
.rangePickerPMP .ant-picker-focused {
  border: 2px solid rgba(114, 46, 209, 1);
  background-color: rgb(249, 240, 255);
  border-color: rgba(114, 46, 209, 1) !important;
  border-right-width: 2px !important;
}

.rangePickerPMP .ant-picker-input > input {
  color: rgba(114, 46, 209, 1);
  font-weight: bold;
}

.rangePickerPMP svg path {
  fill: rgba(114, 46, 209, 1);
}

.hover-border {
  border: 3px solid;
  -webkit-mask: conic-gradient(from 180deg at top 3px right 3px, #0000 90deg, #000 0) var(--_i, 200%) 0 /200% var(
        --_i,
        3px
      ) border-box no-repeat,
    conic-gradient(at bottom 3px left 3px, #0000 90deg, #000 0) 0 var(--_i, 200%) / var(--_i, 3px) 200% border-box
      no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: 0.3s, -webkit-mask-position 0.3s 0.3s;
}
.hover-border:hover {
  --_i: 100%;
  transition: 0.3s, -webkit-mask-size 0.3s 0.3s;
}

.selectFieldPMP {
  .ant-select-selector {
    border-width: 2px !important;
    background-color: #f9f0ff !important;
    color: #722ed1 !important;
    border-color: #722ed1 !important;
    padding: 0px 2px;
    display: flex;
    align-items: center;

    .ant-select-selection-item-content {
      font-weight: bold;
    }
  }

  .ant-select-selection-item {
    font-weight: bold;
    height: 24px;
    display: flex;
    align-items: center;
  }
  .ant-select-multiple .ant-select-selection-item {
    border-radius: 0px;
    padding: 0px 4px;
    margin: 0px 1px;
  }
  .ant-select-multiple .ant-select-selection-search-input {
    height: 27.8px !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }

  .inputPMP {
    border-width: 2px !important;
    background-color: #f9f0ff !important;
    color: #722ed1 !important;
    border-color: #722ed1 !important;
    font-weight: bold;
  }
}

.ant-menu-submenu-popup {
  display: none;
}

.appsflyer_filter {
  width: 40%;
}

// .bar .highcharts-axis-line,
// .bar .highcharts-grid-line:last-child {
//   opacity: 0;
// }

.bar .highcharts-xaxis-labels > span {
  left: 0 !important;
}

.ant-menu-submenu-popup {
  display: none;
}

.highcharts-container {
  overflow: visible !important;
}

.highcharts-legend-item span {
  padding: 0px 8px 0px 2px;
  overflow: hidden;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 143.556px;
}

.legend_truncate {
  padding: 0px 8px 0px 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 145px;
}

.highcharts-tooltip-container {
  z-index: 1000 !important;
}
