// _input.scss
// 
// Template's input styles.
// 

.ant-input {
	border-radius: 6px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	font-weight: normal;
}
.layout-dashboard-rtl {
	.header-control {
		.header-search {
			.ant-input {
				&:not(:first-child) {
					padding-right: 32px;
					padding-left: 11px;
				}
			}
		}
	}

	.ant-input-affix-wrapper .ant-input-prefix {
		right: 12px;
		left: auto;
	}
}