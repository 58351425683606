// _table.scss
//
// Template's tables styles.
//

// Tables
.ant-table {
  font-size: 14px;
}
.ant-table-thead > tr > th {
  font-weight: $fw-bold;
  position: relative;
  color: #000000d9;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease;
}
.ant-table-tbody > tr > td {
  padding: 5px 10px;
  border-bottom: 1px solid $color-gray-4;
}

.ant-table-tbody > tr > td h6 {
  font-size: 14px;
  font-weight: $fw-semibold;
  color: $color-gray-12;
}
.ant-table-tbody .ant-progress-bg {
  height: 3px !important;
}
.ant-table-tbody .ant-progress {
  line-height: 3px;
  margin-top: 3px;
  display: block;
}
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background-color: rgba(0, 0, 0, 0.03);
}
.table-upload-btn {
  margin: 20px;
  margin-top: 10px;
  .ant-btn {
    box-shadow: none;
    font-weight: $fw-semibold;

    &:hover svg path,
    &:focus svg path,
    &:active svg path {
      fill: $color-primary;
    }
  }
  svg {
    vertical-align: middle;
    margin-right: 5px;
    path {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.ant-table-body {
  overflow: auto;

  @media (min-width: $xl) {
    overflow: visible;
  }

  .btn-edit {
    font-weight: $fw-semibold;
    color: $color-muted;
  }
}
.table-avatar-info {
  display: flex;

  .ant-avatar {
    margin-right: 20px;
  }
  .avatar-info {
    h6 {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 3px;
    }
    p {
      font-size: 14px;
      font-weight: $fw-regular;
      color: $color-gray-7;
    }
    > * {
      margin: 0;
    }
  }
}

.layout-dashboard-rtl {
  .ant-table table {
    text-align: right;
  }
  .table-upload-btn svg {
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 0;
  }
}
// / Tables
