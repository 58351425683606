// _sign-up.scss
// 
// Template's sign up page styles.
// 


// Sign Up Page
.layout-default.layout-sign-up {
	padding: 11px;

	.ant-layout-header {
		background-color: $color-gray-1;
		color: $color-gray-12;
		box-shadow: none;
		padding: 20px;
		margin: -12px -12px 0;
					
		@media( min-width: $lg ){
			background-color: transparent;
			color: $color-gray-1;
			margin: 0;
			padding: 0 20px;
		}

		.header-brand {
			padding: 0px;
			
			@media( min-width: $lg ){
			}
			h6 {
				color: $color-gray-12;
					
				@media( min-width: $lg ){
					color: $color-gray-1;
				}
			}
		}
		.header-nav {
			a {
				color: $color-gray-12;
					
				@media( min-width: $lg ){
					color: $color-gray-1;
				}
				svg path {
					fill: $color-gray-12 !important;
					
					@media( min-width: $lg ){
						fill: $color-gray-1 !important;
					}
				}

				&.router-link-active {
					color: $color-gray-12;
					
					@media( min-width: $lg ){
						color: $color-gray-1;
					}
					svg path {
						fill: $color-gray-12;
					
						@media( min-width: $lg ){
							fill: $color-gray-1;
						}
					}
				}
			}
			.ant-menu-horizontal {
				line-height: 26px;
				padding: 10px 0;

				span {
					color: $color-gray-12;
					
					@media( min-width: $lg ){
						color: $color-gray-1;
					}
				}
			}
		}
		.header-btn .ant-btn-primary {
			@media( min-width: $lg ){
				background-color: $color-gray-1;
				color: $color-gray-12;
				border: none;
			}
		}
		
	}
	.ant-layout-content {
		padding-top: 0;
	}
}

.sign-up-header {
	height: 550px;
	margin-top: -120.8px;
	padding-top: 137.8px;
	border-radius: 12px;
	box-shadow: $shadow-1;
	background-size: cover;
	background-position: center;
	text-align: center;

	@media( min-width: $lg ){
		margin-top: -62.8px;
	}

	* {
		color: $color-gray-1;
	}

	.content {
		padding-top: 40px;
		max-width: 480px;
		margin: auto;

		h1 {
			font-size: 36px;

			@media( min-width: $md) {
				font-size: 48px;
			}
		}
	}
}
// Sign Up Page