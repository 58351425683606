// _sign-in.scss
// 
// Template's sign in page styles.
// 


// Sign In Page
.sign-in {
    padding: 0px 20px;
	.col-img img {
		width: 100%;
		max-width: 500px;
		margin: auto;
		display: block;

		@media( min-width: $lg) {
			margin: 0;
		}
	}
	.col-form .ant-switch {
		margin-right: 5px;
	}
	.ant-form-item-label {
		line-height: 28px;
	}
	.ant-form-item label {
		font-size: 13px;
		font-weight: $fw-semibold;
		color: $color-gray-12;
	}
}
// / Sign In Page